.form {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    padding: 20px;
    background: var(--tg-theme-bg-color);
    color: var(--tg-theme-text-color);
    text-decoration-color: var(--tg-theme-bg-color);
}

.input {
    /* width: 100%; */
    padding: 10px;
    margin-top: 15px;
}

.select {
    padding: 10px;
    margin-top: 15px;
}

.body {
    background: var(--tg-theme-bg-color);
    color: var(--tg-theme-text-color);
}

/* bg_color	String	Optional. Background color in the #RRGGBB format.
Also available as the CSS variable var(--tg-theme-bg-color).

text_color	String	Optional. Main text color in the #RRGGBB format.
Also available as the CSS variable var(--tg-theme-text-color).

hint_color	String	Optional. Hint text color in the #RRGGBB format.
Also available as the CSS variable var(--tg-theme-hint-color).

link_color	String	Optional. Link color in the #RRGGBB format.
Also available as the CSS variable var(--tg-theme-link-color).

button_color	String	Optional. Button color in the #RRGGBB format.
Also available as the CSS variable var(--tg-theme-button-color).

button_text_color	String	Optional. Button text color in the #RRGGBB format.
Also available as the CSS variable var(--tg-theme-button-text-color).

secondary_bg_color	String	Optional. Bot API 6.1+ Secondary background color in the #RRGGBB format.
Also available as the CSS variable var(--tg-theme-secondary-bg-color).

header_bg_color	String	Optional. Bot API 7.0+ Header background color in the #RRGGBB format.
Also available as the CSS variable var(--tg-theme-header-bg-color).

bottom_bar_bg_color	String	Optional. Bot API 7.10+ Bottom background color in the #RRGGBB format.
Also available as the CSS variable var(--tg-theme-bottom-bar-bg-color).

accent_text_color	String	Optional. Bot API 7.0+ Accent text color in the #RRGGBB format.
Also available as the CSS variable var(--tg-theme-accent-text-color).

section_bg_color	String	Optional. Bot API 7.0+ Background color for the section in the #RRGGBB format. It is recommended to use this in conjunction with secondary_bg_color.
Also available as the CSS variable var(--tg-theme-section-bg-color).

section_header_text_color	String	Optional. Bot API 7.0+ Header text color for the section in the #RRGGBB format.
Also available as the CSS variable var(--tg-theme-section-header-text-color).

section_separator_color	String	Optional. Bot API 7.6+ Section separator color in the #RRGGBB format.
Also available as the CSS variable var(--tg-theme-section-separator-color).

subtitle_text_color	String	Optional. Bot API 7.0+ Subtitle text color in the #RRGGBB format.
Also available as the CSS variable var(--tg-theme-subtitle-text-color).

destructive_text_color	String	Optional. Bot API 7.0+ Text color for destructive actions in the #RRGGBB format.
Also available as the CSS variable var(--tg-theme-destructive-text-color). */